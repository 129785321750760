import React from "react";
import ReactDOM from "react-dom/client";
import "assets/css/index.css";
import ReactGA from "react-ga";
import App from "App";

const TRACKING_ID = "UA-160622134-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);
