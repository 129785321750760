import React from "react";
import styled from "styled-components";
import { PostProps } from "utils/postsconfig";
import {
  borderRadius,
  boxShadow,
  fontFamily,
  fontSize,
  fontWeight,
  spacing,
  fontColor,
  lineHeight,
  breakPoints,
  borderColor,
} from "utils/styles";

type WorkCardProps = Pick<
  PostProps,
  "company" | "title" | "subtitle" | "tags" | "image" | "color"
> & { verticalView?: Boolean };

const WorkCardWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: ${spacing.large_24};
  box-shadow: ${boxShadow.medium};
  border-radius: ${borderRadius.large};
  padding: ${spacing.large_24};
  background: ${(props) => (props.color ? props.color : undefined)};
  transition: transform 400ms ease;
  height: 290px;

  @media (min-width: ${breakPoints.smallDevices}) {
    flex-direction: row;
    height: 382px;
    padding-left: ${spacing.xxlarge_80};
    padding-right: ${spacing.xxlarge_80};
  }

  &:hover {
    border: solid 1px ${borderColor.transparent};
    transform: scale(1.025);
  }
`;

const LeftSideWrapper = styled.div`
  flex: 0 0 40%;
  gap: ${spacing.xsmall_4};
  align-self: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const FigureWrapper = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Company = styled.div`
  font-size: ${fontSize.tiny};
  color: ${fontColor.quartenary};

  @media (min-width: ${breakPoints.smallDevices}) {
    font-size: ${fontSize.small};
  }
`;

const Title = styled.div`
  font-family: ${fontFamily.DMSerif};
  font-size: ${fontSize.regular};
  font-weight: ${fontWeight.bold};
  color: ${fontColor.secondary};
  line-height: ${lineHeight.small};

  @media (min-width: ${breakPoints.smallDevices}) {
    flex-direction: row;
    font-size: ${fontSize.xlarge};
  }
`;

const Tags = styled.div`
  font-size: ${fontSize.tiny};
  color: ${fontColor.quartenary};

  @media (min-width: ${breakPoints.smallDevices}) {
    font-size: ${fontSize.small};
  }
`;

const ImageWrapper = styled.img<{ cropPhoto?: Boolean, smallPhoto?: Boolean }>`
  width: ${(props) => (props.smallPhoto ? "unset" : "100%")};
  height: ${(props) => (props.smallPhoto ? "400px" : "auto")};
  text-align: center;
  position: relative;

  @media (min-width: ${breakPoints.smallDevices}) {
    top: ${(props) => (props.cropPhoto ? "50px" : "unset")};
  }
`;

export default function WorkCard({
  company,
  title,
  tags,
  image,
  color,
  cropPhoto,
  smallPhoto
}: WorkCardProps & { cropPhoto?: Boolean, smallPhoto?: Boolean }) {
  return (
    <WorkCardWrapper color={color.light}>
      <LeftSideWrapper>
        <Company>{company}</Company>
        <Title>{title}</Title>
        <Tags>{tags}</Tags>
      </LeftSideWrapper>
      <FigureWrapper>
        <ImageWrapper
          smallPhoto={smallPhoto}
          cropPhoto={cropPhoto}
          src={image.url}
          alt={image.altText}
        />
      </FigureWrapper>
    </WorkCardWrapper>
  );
}
