import TWBuildingMonitoring from "posts/tw-building-monitoring";
import TWSheets from "posts/tw-sheets";
import TWWorkstationBooking from "posts/tw-workstation-booking";
import { backgroundColor } from "./styles";

export type PostProps = {
  id: number;
  company: string;
  title: string;
  subtitle?: string;
  shortTitle: string;
  tags?: string;
  icon: string,
  image: {
    url: string;
    url2?: string,
    altText: string;
    localUrl?: Boolean;
  };
  hidden?: Boolean; //Hide from homepage and do not register route
  color: {
    light: string;
    lighter: string;
    dark: string
  };
  role?: string;
  tools?: string;
  process?: string;
  timeline?: string;
  content?: {
    fileName: string;
    fileType: string;
  };
  sections: Array<{
    title: string;
    url: string;
  }>;
  discoveryItems?: any;
  article?: JSX.Element;
};

export const posts: Array<PostProps> = [
  {
    id: 1,
    company: "Thoughtwire",
    title: "Improving the building management experience",
    shortTitle: "tw-building-monitoring",
    subtitle:
      "My team and I leveraged data to make it easier for smart building managers to monitor buildings.",
    tags: "Research • Design • Documentation",
    icon: "💻",
    image: {
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1665635969/Portfolio_v3/monitoring_ngdccf.png",
      altText: "A macbook displaying alerts overlayed on a floor map, and list view of alerts.",
    },
    color: {
      light: backgroundColor.purple100,
      lighter: backgroundColor.purple200,
      dark: backgroundColor.purple300,
    },
    role: "Designer",
    tools: "Figma",
    process: "Research → Design → Document",
    timeline: "3 months",
    sections: [
      {
        title: "Context",
        url: "#Context",
      },
      {
        title: "Discovery",
        url: "#Discovery",
      },
      {
        title: "Design",
        url: "#Design",
      },
      {
        title: "Development",
        url: "#Development",
      },
      {
        title: "Impact",
        url: "#Impact",
      },
    ],
    article: <TWBuildingMonitoring />,
  },
  {
    id: 2,
    company: "Thoughtwire",
    title: "Building an accessible workstation booking experience",
    shortTitle: "tw-workstation-booking",
    subtitle:
      "My team and I leveraged data to make it easier for smart building managers to monitor buildings.",
    tags: "Research • Design • Documentation",
    icon: "📱",
    image: {
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1666570284/Portfolio_v3/Blue_hero_okzdl1.png",
      url2: "https://res.cloudinary.com/drsp4xifi/image/upload/v1666480863/Portfolio_v3/Hero_cvhqjh.png",
    
      altText: "A phone displaying a page for booking workstations. To the right is another phone displaying the booking summary of a workstation replacement booking.",
    },
    color: {
      light: backgroundColor.blue100,
      lighter: backgroundColor.blue200,
      dark: backgroundColor.blue300,
    },
    role: "Designer",
    tools: "Figma",
    process: "Research → Design → Document",
    timeline: "3 months",
    sections: [
      {
        title: "Context",
        url: "#Context",
      },
      {
        title: "Discovery",
        url: "#Discovery",
      },
      {
        title: "Design",
        url: "#Design",
      },
      {
        title: "Development",
        url: "#Development",
      },
      {
        title: "Impact",
        url: "#Impact",
      },
    ],
    article: <TWWorkstationBooking />,
  },
  {
    id: 3,
    company: "Thoughtwire",
    title: "Improving the usability of sheets in mobile apps",
    shortTitle: "tw-sheets",
    subtitle: "",
    tags: "Design Systems • Documentation",
    icon: "📱",
    image: {
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1666572798/Portfolio_v3/Green_hero_lidkho.png",
      url2: "https://res.cloudinary.com/drsp4xifi/image/upload/v1666572799/Portfolio_v3/Hero_white_lt9kla.png",
      altText: "An Iphone and a Google pixel showing different types of sheets.",
    },
    color: {
      light: backgroundColor.green100,
      lighter: backgroundColor.green200,
      dark: backgroundColor.green300,
    },
    role: "Designer",
    tools: "Figma",
    process: "Research → Design System → Document",
    timeline: "1 Month",
    sections: [
      {
        title: "Context",
        url: "#Context",
      },
      {
        title: "Discovery",
        url: "#Discovery",
      },
      {
        title: "Design",
        url: "#Design",
      },
      {
        title: "Documentation",
        url: "Documentation",
      },
      {
        title: "Impact",
        url: "Impact",
      },
    ],
    article: <TWSheets />,
  },
 
  // {
  //   id: 3,
  //   company: "Quora",
  //   title: "Increasing user engagement with comment previews",
  //   shortTitle: "qr-comment-previews",
  //   subtitle: "",
  //   tags: "Design • React • A/B Testing",
  //   icon: "📱",
  //   image: {
  //     url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1665951389/Portfolio_v3/Comment_preview_thumbnail_sfa1lh.png",
  //     url2: "https://res.cloudinary.com/drsp4xifi/image/upload/v1665952091/Portfolio_v3/Comment_preview_thumbnail_white_2_lzypao.png",
  //     altText: "An Iphone mockup showing Quora notifications",
  //   },
  //   color: {
  //     light: backgroundColor.green100,
  //     lighter: backgroundColor.green200,
  //     dark: backgroundColor.green300,

  //   },
  //   role: "Designer",
  //   tools: "Figma",
  //   process: "Research → Design → Documentation",
  //   timeline: "1 Month",
  //   sections: [
  //     {
  //       title: "Context",
  //       url: "#Context",
  //     },
  //     {
  //       title: "Research",
  //       url: "#Research",
  //     },
  //     {
  //       title: "Key Features",
  //       url: "#Key-Features",
  //     },
  //   ],
  //   article: <TWCommentPreviews />,
  // },
 
];
