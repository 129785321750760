import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PostNavigation from "components/navigation/PostNavigation";
import Post from "pages/post";
import { PostProps, posts } from "utils/postsconfig";

const logo = {
  altText:
    "Website logo which is a memoji of me with hair pulled in a big bun.",
  url: "/",
};

export const PostContext = React.createContext<{ post: PostProps | undefined }>(
  { post: posts[1] }
);

const PostLayoutWrapper = styled.div`
  scroll-behavior: smooth;
`;
export default function PostLayout() {
  const params = useParams();
  const postShortTitle = params.shortTitle;
  const post = posts.find((post) => post.shortTitle === postShortTitle);

  return (
    <PostLayoutWrapper>
      {post?.id && (
        <PostContext.Provider value={{ post: post }}>
          <PostNavigation logo={logo} />
          <Post />
        </PostContext.Provider>
      )}
    </PostLayoutWrapper>
  );
}
