import React, { useContext } from "react";
import { Link as SmoothScrollElement } from "react-scroll";
import styled from "styled-components";
import LogoImage from "assets/images/ama-memoji.png";
import { PostContext } from "layouts/postLayout";
import { PostProps, posts } from "utils/postsconfig";
import {
  borderRadius,
  fontColor,
  spacing,
  breakPoints,
  fontSize,
  backgroundColor,
  borderColor,
} from "utils/styles";
import { Link } from "react-router-dom";

const NavigationControls = ({ id }: Pick<PostProps, "id">) => {
  const linkStyles = {
    marginLeft: 16,
  };
  const nextPage = posts.find((item) => item.id === id + 1);
  const previousPage = posts.find((item) => item.id === id - 1);

  return (
    <NavigationControlsWrapper>
      {previousPage && (
        <LinkWrapper>
          <Link
            className="postLink"
            to={`/posts/${previousPage.shortTitle}`}
            style={linkStyles}
          >
            Previous
          </Link>
        </LinkWrapper>
      )}
      {nextPage && (
        <LinkWrapper>
          <Link
            className="postLink"
            to={`/posts/${nextPage.shortTitle}`}
            style={linkStyles}
          >
            Next
          </Link>
        </LinkWrapper>
      )}
    </NavigationControlsWrapper>
  );
};

const ScrollControls = ({
  sections,
  color,
}: Pick<PostProps, "sections" | "color">) => {
  return (
    <ScrollControlsWrapper color={color.light}>
      {sections.map((item, index) => (
        <LinkWrapper key={index} color={color.light}>
          <SmoothScrollElement
            smooth={true}
            activeClass="active"
            to={item.title}
            spy={true}
            offset={-100}
          >
            {item.title}
          </SmoothScrollElement>
        </LinkWrapper>
      ))}
    </ScrollControlsWrapper>
  );
};

export default function PostNavigation({ logo }: any) {
  const { post } = useContext(PostContext);
  return (
    <PostNavigationWrapper>
      <Link to={"/"}>
        <LogoWrapper src={LogoImage} alt={logo.altText} />
      </Link>
      {post && (
        <>
          <ScrollControls sections={post.sections} color={post.color} />
          <NavigationControls id={post.id} />
        </>
      )}
    </PostNavigationWrapper>
  );
}

/* STYLES */
const LogoWrapper = styled.img`
  height: 60px;
  transition: transform 200ms ease;

  &:hover {
    transform: scale(1.05) rotate(3deg);
  }
`;

const LinkWrapper = styled.div`
  a {
    position: relative;
    cursor: pointer;
    display: block;

    &:hover {
      color: ${fontColor.secondary};
    }

    &.active {
      color: ${fontColor.secondary};
      background-color: ${(props) => props.color || "unset"};

      :after {
        content: "";
        width: 4px;
        height: 4px;
        background-color: ${(props) => props.color || fontColor.quartenary};
        border-radius: ${borderRadius.round};
        position: absolute;
        left: 50%;
        bottom: 6px;
      }
    }
  }
`;

const ScrollControlsWrapper = styled.div`
  border-radius: ${borderRadius.large};
  font-size: ${fontSize.tiny};
  /* width: ${breakPoints.xSmallDevices}; */
  display: none;
  position: relative;
  background-color: ${backgroundColor.white};
  border: solid 1px ${borderColor.transparent};
  padding: ${spacing.xxsmall_2};

  a {
    padding: ${spacing.xsmall_4} ${spacing.large_24};
    border-radius: ${borderRadius.large};
  }

  @media (min-width: ${breakPoints.smallDevices}) {
    display: flex;
    flex-direction: row;
    gap: ${spacing.xsmall_4};
    justify-content: center;
  }
`;

const NavigationControlsWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const PostNavigationWrapper = styled.nav`
  height: 80px;
  padding: 0 ${spacing.medium_16};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${fontColor.quartenary};
  position: fixed;
  z-index: 2;
  width: 100%;
  grid-template-columns: repeat();
  /* max-width: ${breakPoints.xLargeDevices}; */
  background: ${backgroundColor.whiteTransparent};

  @media (min-width: ${breakPoints.mediumDevices}) {
    padding: 0 ${spacing.xxlarge_80};
    grid-template-columns: 120px auto 120px;
    display: grid;
  }
`;
