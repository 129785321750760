import React, { useContext } from "react";
import styled from "styled-components";
import {
  boxShadow,
  breakPoints,
  fontColor,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "utils/styles";
import { PostContext } from "layouts/postLayout";

const StickyWrapper = styled.div`
  width: 230px;
  height: 220px;
  position: absolute;
  top: 140px;
  font-size: ${fontSize.small};
  left: calc(100vw * (0.03));
  overflow: hidden;
  padding: ${spacing.large_24};
  border-radius: 0 0 0 30px/45px;
  box-shadow: ${boxShadow.small};
  font-weight: ${fontWeight.medium};
  background-color: ${(props) => props.color};
  display: none;
  font-size: ${fontSize.tiny};
  line-height: ${lineHeight.large};
  color: ${fontColor.quartenary};

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 25px;
    background: ${(props) => props.color};
    box-shadow: 3px -2px 10px rgba(0, 0, 0, 0.2),
      inset 15px -15px 15px rgba(0, 0, 0, 0.149);
    left: 0;
    bottom: 0;
    z-index: 2;
    transform: skewX(25deg);
  }

  @media (min-width: ${breakPoints.mediumDevices}) {
    display: block;
  }
`;

export default function Sticky({ children }: any) {
  const { post } = useContext(PostContext);

  return <StickyWrapper color={post?.color.light}>{children}</StickyWrapper>;
}
