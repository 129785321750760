import { Text } from "components/post/PostContent";
import React from "react";
import styled from "styled-components";
import {
  breakPoints,
  fontColor,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "utils/styles";

export default function Profile() {
  const { avatar } = profile;

  return (
    <ProfileWrapper>
      <img src={avatar.url} alt={avatar.altText} />
      <div>
        <h3>Hi, my name is Chiamaka, a product designer in Toronto.</h3>
        <Text>
          I currently work on the design team at Thoughtwire, where we are
          creating solutions to improve the building experience for owners and
          tenants. I also enjoy working with and contributing to design systems
          to make it easier for folks on our product teams to design and build.
        </Text>
        <Text>
          When I'm not designing, I'm trying to build something in code, like
          this portfolio 🤓, or watching a movie 🍿.
        </Text>
        <Text>
          If you wish to connect, you can{" "}
          <a
            href="https://www.linkedin.com/in/ChiamakaIwuanyanwu"
            target="_blank"
            rel="noreferrer"
          >
            find me on LinkedIn
          </a>
          .
        </Text>
      </div>
    </ProfileWrapper>
  );
}

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${breakPoints.xSmallDevices};
  gap: ${spacing.large_24};
  color: ${fontColor.secondary};

  img {
    height: 168px;
    width: 168px;
  }

  h3 {
    font-size: ${fontSize.small};
    font-family: ${fontFamily.DMSans};
    line-height: ${lineHeight.small};
    margin-bottom: ${spacing.medium_16};
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    font-weight: ${fontWeight.medium};
  }
`;

const profile = {
  avatar: {
    url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1624893246/Portfolio_v2/About/Ellipse_75_ujgztj.png",
    altText: "Me (Ama) taking a photo of my reflection in a macbook.",
  },
  mainText: `Hi️! My name is Chiamaka, a product designer in Toronto.`,
  learnings: [
    {
      label: "Something i’ve learned recently about design",
      content: "A picture is worth a thousand words.",
    },
    {
      label: "Something i’ve learned recently about people",
      content: "People rock! I'm fascinated about how people solve problems.",
    },
  ],
  links: [],
};
