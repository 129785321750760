import React from "react";
import styled from "styled-components";

export default function FadeIn({ children }: any) {
  return <TransitionWrapper>{children}</TransitionWrapper>;
}

/* STYLES */
const TransitionWrapper = styled.div`
  animation: 500ms ease-out 0s 1 fadeIn;
`;
