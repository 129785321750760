export const color = {
  /* neutrals */
  gray700: "#212529",
  gray600: "#343A40",
  gray500: "#495057",
  gray400: "#6C757D",
  gray300: "#DEE2E6",
  gray200: "#E9ECEF",
  gray100: "#F8F9FA",
  white: "#FFFFFF",
  grayTransparent: "#0000000a",
  whiteTransparent: "#fffcfa82",

  /* purples */
  purple300: "#35008A",
  purple200: "#D6BDFF",
  purple100: "#F7F2FF",

  /* reds */
  red300: "#8A0000",
  red200: "#FFBDBD",
  red100: "#FFF2F2",

  /* oranges */
  orange300: "#E6700A",
  orange200: "#FCDCBF",
  orange100: "#FEF7F0",

  /* greens */
  green300: "#1A7070",
  green200: "#C9F2F2",
  green100: "#F3FCFC",

  /* blues */
  blue300: "#002F8A",
  blue200: "#BDD3FF",
  blue100: "#F0F5FF",

  /* pinks */
  pink300: "#8E0F72",
  pink200: "#F9C3ED",
  pink100: "#FEF1FB",
};
