import React from "react";
import styled from "styled-components";
import { fontColor, fontSize, spacing } from "utils/styles";
import { H2Serif } from "./PostContent";

export default function ContentWithIcon({ icon, title, children }: any) {
  return (
    <div>
      <Icon>{icon}</Icon>
      <H2Serif>{title}</H2Serif>
      <Content>{children}</Content>
    </div>
  );
}


const Icon = styled.div`
  font-size: ${fontSize.large};
  margin-bottom: ${spacing.medium_16};
`;

const Content = styled.div`
  font-size: ${fontSize.small};
  line-height: 150%;
  color: ${fontColor.quartenary};
`;
