import React, { useContext } from "react";
import {
  breakPoints,
  fontColor,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "utils/styles";
import { PostContext } from "layouts/postLayout";
import styled from "styled-components";
import { H2SansUppercase } from "./PostContent";

export const PostSummary = ({ children }: any) => {
  const { post } = useContext(PostContext);
  const useSmallImage = post?.id === 3 || post?.id === 2;

  return (
    <PostSummaryWrapper
      id="Context"
      className="summary"
      color={post?.color.light}
    >
      <ContentWrapper>
        <h1>{post?.title}</h1>
        <H2SansUppercase>CONTEXT</H2SansUppercase>
        {children}
      </ContentWrapper>
      <Figure smallImage={useSmallImage}>
        <img
          src={post?.image.url2 || post?.image.url}
          alt={post?.image.altText}
          
        />
      </Figure>
    </PostSummaryWrapper>
  );
};

const PostSummaryWrapper = styled.div`
  align-items: center;
  overflow-y: hidden;
  padding-top: ${spacing.xlarge_48};
  position: relative;

  h1,
  h2,
  p {
    max-width: ${breakPoints.xSmallDevices};
    margin: auto;
    text-align: left;
  }

  h1 {
    font-size: ${fontSize.xlarge};
    font-weight: ${fontWeight.bold};
    margin-bottom: ${spacing.large_24};
    font-family: ${fontFamily.DMSerif};
    color: ${fontColor.secondary};
    line-height: ${lineHeight.small};

    @media (min-width: ${breakPoints.smallDevices}) {
      font-size: ${fontSize.xxxlarge};
    }
  }

  img {
    width: 100%;
    height: auto;
    
  }

  p {
    margin-bottom: ${spacing.medium_16};
    font-size: ${fontSize.small};
    line-height: ${lineHeight.large};
  }
`;

const ContentWrapper = styled.div`
  padding-left: ${spacing.large_24};
  padding-right: ${spacing.large_24};
  margin: ${spacing.xlarge_48} auto 0;
  position: relative;

  @media (min-width: ${breakPoints.mediumDevices}) {
    margin: ${spacing.xxlarge_80} auto 0;
    padding-left: ${spacing.xxlarge_80};
    padding-right: ${spacing.xxlarge_80};
  }
`;

const Figure = styled.figure<{ smallImage: Boolean }>`
  text-align: center;
  padding: ${spacing.large_24};

  @media (min-width: ${breakPoints.mediumDevices}) {
    padding: ${spacing.large_24} ${spacing.xxlarge_80};
  }
  img {
    max-width: ${breakPoints.largeDevices};
    width: ${(props) => (props.smallImage ? "unset" : "100%")};
    height: ${(props) => (props.smallImage ? "400px" : "auto")};
    margin: auto;
  }
`;
