import React, { useContext } from "react";
import { PostContext } from "layouts/postLayout";
import PostFooter from "components/navigation/PostFooter";
import styled from "styled-components";
import { spacing } from "utils/styles";

export default function Post() {
  const { post } = useContext(PostContext);
  return (
    <Wrapper>
      <section>{post?.article}</section>
      <PostFooter post={post}/>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xlarge_48};
`

