import React from "react";
import styled from "styled-components";
import {
  backgroundColor,
  borderRadius,
  boxShadow,
  breakPoints,
  fontColor,
  spacing,
} from "utils/styles";
import { H2SansUppercase } from "./PostContent";

export function ContainerWithBorder({ children, title }: any) {
  return (
    <ContainerWithBorderWrapper>
      <H2SansUppercase textAlign="center">{title}</H2SansUppercase>
      {children}
    </ContainerWithBorderWrapper>
  );
}

const ContainerWithBorderWrapper = styled.div`
  border-radius: ${borderRadius.large};
  padding: ${spacing.large_24} ${spacing.large_24};
  margin-bottom: ${spacing.medium_16};
  max-width: ${breakPoints.mediumDevices};
  margin: ${spacing.xlarge_48} auto 0;
  background-color: ${backgroundColor.grayUltraLight};
  /* color: ${fontColor.quartenary}; */
  box-shadow: ${boxShadow.large};

  @media (min-width: ${breakPoints.mediumDevices}) {
    padding: ${spacing.xlarge_48} ${spacing.xxlarge_80};
  }
`;
