import React from "react";
import { ContainerWithBorder } from "components/post/ContainerWithBorder";
import ContentWithMedia from "components/post/ContentWithMedia";
import SectionWithGrayBackground from "components/post/ContainerWithGrayBackground";
import PostContent, { Spacer } from "components/post/PostContent";
import { PostSummary } from "components/post/PostSummary";
import Sticky from "components/post/Sticky";
import ContentWithIcon from "components/post/ContentWithIcon";
import Image from "components/post/Image";
import FadeIn from "components/post/FadeIn";
import {
  GridColumns,
  Flex,
  H2SansUppercase,
  H2Serif,
  Section,
  Text,
} from "components/post/PostContent";
import { spacing } from "utils/styles";

export default function TWSheets() {
  return (
    <>
      <FadeIn>
        <Sticky>
          {postMeta.map((item, index) => (
            <Flex key={index}>
              <div>{item.icon}</div>
              <div>{item.label}</div>
            </Flex>
          ))}
        </Sticky>
      </FadeIn>
      <PostSummary>
        <Text>
          A few months ago, the design team at Thoughtwire decided to improve
          how we used sheets in our mobile apps after identifying usability
          issues that impacted the user experience.
        </Text>
        <Text>
          Leveraging platform-specific patterns, I led the effort to standardize
          how we used sheets in the app. By focusing on user goals, we improved
          usability and created an experience that helped users complete tasks.{" "}
        </Text>
      </PostSummary>

      <PostContent>
        <>
          <Section id="Discovery">
            <H2SansUppercase>DISCOVERY</H2SansUppercase>
            <H2Serif>
              Usability issues with sheets impacted the app experience
            </H2Serif>
            <Text>
              With no guidelines on how sheets should behave, we had
              less-than-ideal experiences in our mobile app. In some cases,
              sheets had no visible way of being dismissed. In other cases,
              scrolling was difficult.
            </Text>
            <Text>
              To fix this, I conducted an audit to determine how we used sheets.
              The outcome of this was a grouping of sheets based on the user's
              primary goal.
            </Text>
            <ContainerWithBorder title="4 things we wanted to help users do with sheets">
              <GridColumns columns={2}>
                {discoveryItems.map((item, index) => (
                  <ContentWithIcon
                    icon={item.icon}
                    title={item.title}
                    key={index}
                  >
                    <Text>{item.value}</Text>
                  </ContentWithIcon>
                ))}
              </GridColumns>
              <Image
                url="https://res.cloudinary.com/drsp4xifi/image/upload/v1666224631/Portfolio_v3/Screen_Shot_2022-10-19_at_8.09.37_PM_ntshhf.png"
                alt="During the audit, I documented the purpose of sheets in each use case. I also outlined the sheet's expected behavior in iOS and Android."
                marginTop={spacing.large_24}
                backgroundShadow={true}
                padding={spacing.large_24}
              />
            </ContainerWithBorder>
          </Section>
          <Section id="Design">
            <H2SansUppercase>DESIGN</H2SansUppercase>
            <H2Serif>
              We leveraged native components for an intuitive and accessible
              experience
            </H2Serif>
            <Text>
              For each purpose identified, I defined the behavior on both iOS
              and android platforms. <em>Apple's human interface guidelines</em>{" "}
              and <em>Google's material design</em> were essential resources in
              this process.
            </Text>
            <SectionWithGrayBackground>
              <H2SansUppercase textAlign="center">
                Sheet types we identified to improve usability
              </H2SansUppercase>
              {features.map((item, featureIndex) => (
                <ContentWithMedia
                  key={featureIndex}
                  type={item.media.type}
                  url={item.media.url}
                  alt={item.media.alt}
                  backgroundShadow={false}
                >
                  <ContentWithIcon icon={item.icon} title={item.title}>
                    {item.text.map((text, textIndex) => (
                      <Text key={textIndex}>{text}</Text>
                    ))}
                  </ContentWithIcon>
                </ContentWithMedia>
              ))}
            </SectionWithGrayBackground>
          </Section>
          <Section id="Documentation">
            <H2SansUppercase>DOCUMENTATION</H2SansUppercase>

            <H2Serif>
              Referencing documentation was key in fixing sheet bugs effectively
            </H2Serif>
            <Text>
              I worked with a designer and engineer to ensure that engineers and
              designers understood and could implement the guidelines captured
              in the documentation. Following this, we identified areas in the
              app where sheets did not match the expected behavior as documented
              as bugs.
            </Text>
            <Image
              url="https://res.cloudinary.com/drsp4xifi/image/upload/v1666224218/Portfolio_v3/Dev_sheets_of8v6z.png"
              alt="In Figma and Confluence, I documented the behavior of the sheets. With an engineer, we also added sample code to outline the properties of the sheet."
              marginTop={spacing.xlarge_48}
              backgroundShadow={true}
              padding={spacing.large_24}
            />
          </Section>
          <Section id="Impact">
          <H2SansUppercase>IMPACT</H2SansUppercase>
            <H2Serif>
              Fixing usability issues improved user experience
            </H2Serif>
            <Text>
              With the sheet documentation, we could identify and fix usability
              issues caused by sheets. Additionally, the new sheets standard
              sped up our design process as it became easier to decide when and
              how to use sheets.
            </Text>
            <Spacer/>
          </Section>
        </>
      </PostContent>
    </>
  );
}

const discoveryItems = [
  {
    icon: "📖",
    title: "Read",
    value:
      "The user wants to understand the information and does not need to take action.",
  },
  {
    icon: "👆",
    title: "Select an option",
    value:
      "The user wants to select an option from one or more options. It is a simple task done in one tap and does not require keyboard interactions.",
  },
  {
    icon: "⚙️",
    title: "Change settings",
    value:
      "The user wants to make one or more changes to a setting. Information can be easily changed after it is saved.",
  },
  {
    icon: "✅",
    title: "Submit a form",
    value:
      "The user wants to complete a form. It may involve keyboard interactions, and losing information may be costly.",
  },
];

const postMeta = [
  {
    icon: "👩🏾‍💻",
    label: "Designer",
  },
  {
    icon: "🏢",
    label: "Thoughtwire",
  },
  {
    icon: "🛠",
    label: "Figma",
  },
  {
    icon: "🤝",
    label: "Engineer & Designer",
  },
  {
    icon: "📆",
    label: "2 months (2022)",
  },
];

const features = [
  {
    icon: "📖",
    title: "Read Sheet",
    text: [
      <>
        <em>Read sheets</em> display information where the user's primary
        purpose is reading. They also support progressive content disclosure by
        pulling the sheet up or down.
      </>,
      <>
        Read sheets are either presented as modal or non-modal sheets. On iOS, a
        grabber indicates that users can pull the sheet up or down. A downward
        arrow indicates a similar behavior on non-modal sheets on Android.
      </>,
    ],
    media: {
      type: "image",
      alt: `On iOS, a grabber indicates that users can pull the sheet up or down. A downward arrow indicates a similar behavior on non-modal sheets on Android.`,
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1665874295/Portfolio_v3/Reading_Sheet_pmsuv3.png",
    },
  },
  {
    icon: "👆",
    title: "Select Sheet",
    text: [
      <>
        The <em>Select sheet</em> allows users to select from a list of options.
        It does not have a confirmation action—however, actions taken within the
        sheet act as confirmation and dismiss the sheet.
      </>,
      <>
        We made two significant additions to select sheets. We added a title to
        clarify what task users had to complete and also added an explicit
        dismissal action for accessibility.
      </>,
    ],
    media: {
      type: "image",
      alt: `We added a title to clarify what task users had to complete and also added an explicit dismissal action for accessibility.`,
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1665874440/Portfolio_v3/Select_sheet_qoencj.png",
    },
  },
  {
    icon: "🛠",
    title: "Manage Sheet",
    text: [
      <>
        <em>Manage Sheets</em> allow for making changes to a setting. Changes
        made are applied when a user taps on the confirmation or dismisses the
        sheet.
      </>,
      <>
        Since the changes made are simple and can be quickly altered, we always
        save the changes when the user dismisses the sheet.
      </>,
    ],
    media: {
      type: "image",
      alt: "We added a title to clarify what task users had to complete and also added an confirmation/dismissal action.",
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1665874293/Portfolio_v3/Manage_Sheet_d1frpy.png",
    },
  },
  {
    icon: "✅",
    title: "Submit Sheet",
    text: [
      <>
        <em>Submit sheets</em> are the only sheet type that require keyboard
        interactions. They are used to complete forms and always let the user
        confirm changes when dismissed to prevent errors.
      </>,
      <>
        We used native components and followed platform-specific design patterns
        to fix the scrolling issues we had identified in our app.
      </>,
    ],
    media: {
      type: "image",
      alt: "While we use a large detent sheet in iOS, which does not open to full height, the full-page dialog component we use in Android does.",
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1666571967/Portfolio_v3/Sheets_submit_pldhmd.png",
    },
  },
  {
    icon: "",
    title: "",
    text: [
      <>
        Submit sheets may use other sheet types to complete actions within them.
        For example, when booking a workstation, a submit sheet helps the user
        select the date and time, and a select sheet confirms the booking.
      </>,
    ],
    media: {
      type: "image",
      alt: "",
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1665963040/Portfolio_v3/complex_sheet_5_b881la.png",
    },
  },
];
