import React from "react";
import styled from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PostLayout from "layouts/postLayout";
import { Home } from "pages/home";
import {
  backgroundColor,
  fontColor,
  fontFamily,
  fontSize,
  fontWeight,
} from "utils/styles";
import "assets/css/index.css";
import ScrollToTop from "utils/scrollToTop";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-160622134-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export default function App() {
  return (
    <div>
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <Wrapper>
        <React.StrictMode>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="posts/:shortTitle" element={
              <PostLayout />} />
            </Routes>
            <StyledFooter>
              <span>🫶</span>
              <span>ama</span>
            </StyledFooter>
          </BrowserRouter>
        </React.StrictMode>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  margin: auto;
  font-family: ${fontFamily.DMSans};
  font-weight: ${fontWeight.regular};
  color: ${fontColor.tertiary};
  font-size: ${fontSize.small};
  line-height: 180%;
  background-color: ${backgroundColor.white};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(5%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes logoScale {
    0% {
      transform: scale(1);
      transform: rotate(0deg);
    }
    100% {
      transform: scale(2);
      transform: rotate(10deg);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      transform: 1;
    }
  }
`;

export const StyledFooter = styled.footer`
  height: 60px;
  background-color: ${backgroundColor.grayUltraLight};
  text-align: center;
  font-size: ${fontSize.tiny};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${fontColor.quartenary};
  line-height: normal;
`;
