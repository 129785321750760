import React, { ReactElement } from "react";
import styled from "styled-components";
import {
  borderRadius,
  breakPoints,
  fontColor,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "utils/styles";

type Props = {
  children: ReactElement;
};

export default function PostContent({ children }: Props) {
  return <Wrapper>{children}</Wrapper>;
}

/* STYLES */
const Wrapper = styled.div`
padding-left: ${spacing.large_24};
    padding-right: ${spacing.large_24};
  @media (min-width: ${breakPoints.smallDevices}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: ${spacing.medium_16};
`;

export const Section = styled.section`
  padding: ${spacing.xxlarge_80} 0 ${spacing.large_24};
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  max-width: ${breakPoints.xSmallDevices};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing.medium_16};
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};
`;

export const H2Serif = styled.h2`
  max-width: ${breakPoints.xSmallDevices};
  width: 100%;
  margin: auto;
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.DMSerif};
  margin-top: 0;
  line-height: ${lineHeight.small};
  margin-bottom: ${spacing.medium_16};

  @media (min-width: ${breakPoints.smallDevices}) {
    font-size: ${fontSize.xlarge};
  }
`;

export const H3Serif = styled.h3`
  max-width: ${breakPoints.xSmallDevices};
  margin: auto;
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.bold};
  margin-top: 0;
  line-height: ${lineHeight.small};
  margin-bottom: ${spacing.medium_16};

  @media (min-width: ${breakPoints.smallDevices}) {
    font-size: ${fontSize.small};
  }
`;

export const H2SansUppercase = styled.h2<{ textAlign?: string }>`
  text-transform: capitalize;
  font-size: ${fontSize.tiny};
  font-weight: ${fontWeight.regular};
  color: ${fontColor.quartenary};
  margin-bottom: ${spacing.small_12};
  max-width: ${breakPoints.xSmallDevices};
  margin: auto;
  width: 100%;
  text-align: ${(props) => props.textAlign || "left"};
  letter-spacing: 0.4px;
  font-weight: ${fontWeight.medium};
`;

export const GridColumns = styled.div<{ columns?: number }>`
  display: grid;
  gap: ${spacing.medium_16};
  padding: ${spacing.large_24} 0;

  @media (min-width: ${breakPoints.smallDevices}) {
    padding: ${spacing.large_24} 0;
    gap: ${spacing.xlarge_48};
    font-size: ${fontSize.xlarge};
    grid-template-columns: repeat(
      ${(props) => (props.columns ? props.columns : 3)},
      1fr
    );
  }
`;

export const SmallText = styled.span`
  font-size: ${fontSize.tiny};
  border-radius: ${borderRadius.medium};
  font-weight: ${fontWeight.medium};
  display: block;
`;

export const Spacer = styled.div`
  height: 50px;
`;
