import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { posts } from "utils/postsconfig";
import {
  backgroundColor,
  borderColor,
  borderRadius,
  breakPoints,
  fontColor,
  fontSize,
  fontWeight,
  spacing,
} from "utils/styles";

const WorkCardSmall = ({ title, tags, color, icon }: any) => (
  <WorkCardSmallWrapper color={color.light}>
    <PostColor color={color.lighter}>{icon}</PostColor>
    <div>
      <Title>{title}</Title>
      <Tags>{tags}</Tags>
    </div>
  </WorkCardSmallWrapper>
);

const PostColor = styled.div`
  flex: 0 0 40px;
  height: 40px;
  display: flex;
  place-items: center;
  background-color: ${(props) =>
    props.color ? props.color : backgroundColor.grayLight};
  border-radius: ${borderRadius.medium};
  justify-content: center;
  font-size: ${fontSize.regular};
`;

const WorkCardSmallWrapper = styled.div`
  border: solid 1px ${borderColor.transparent};
  border-radius: ${borderRadius.large};
  padding: ${spacing.large_24};
  transition: transform 200ms ease;
  display: flex;
  gap: ${spacing.small_12};
  align-items: center;
  height: 100%;

  :hover {
    background-color: ${(props) =>
      props.color ? props.color : backgroundColor.grayUltraLight};
    transform: scale(1.025);
  }
`;

const Title = styled.div`
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.bold};
  line-height: normal;
  margin-bottom: ${spacing.xsmall_4};
`;
const Tags = styled.div`
  font-size: ${fontSize.tiny};
  line-height: normal;
  color: ${fontColor.quartenary};
`;

export default function PostFooter({ post }: any) {
  const currentPostIndex = posts.indexOf(post);
  const lastPostIndex = posts.length - 1;

  const post1 =
    currentPostIndex === 0
      ? posts[currentPostIndex + 2]
      : posts[currentPostIndex - 1];
  const post2 =
    currentPostIndex === lastPostIndex ? posts[0] : posts[currentPostIndex + 1];

  return (
    <>
      <StyledDiv>
        {post1 && (
          <Link to={`/posts/${post1.shortTitle}`}>
            <WorkCardSmall
              tags={post1.tags}
              color={post1.color}
              title={post1.title}
              icon={post1.icon}
            />
          </Link>
        )}
        {post2 && (
          <Link to={`/posts/${post2.shortTitle}`}>
            <WorkCardSmall
              tags={post2.tags}
              color={post2.color}
              title={post2.title}
              icon={post2.icon}
            />
          </Link>
        )}
      </StyledDiv>
    </>
  );
}

export const StyledDiv = styled.div`
  display: grid;
  gap: ${spacing.medium_16};
  margin: auto;
  justify-content: space-between;
  padding: ${spacing.xlarge_48} ${spacing.large_24};

  a {
    display: block;
  }

  @media (min-width: ${breakPoints.smallDevices}) {
    grid-template-columns: repeat(2, 1fr);
    gap: ${spacing.xlarge_48};
  }
`;
