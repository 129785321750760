import React from "react";
import { ContainerWithBorder } from "components/post/ContainerWithBorder";
import ContentWithMedia from "components/post/ContentWithMedia";
import SectionWithGrayBackground from "components/post/ContainerWithGrayBackground";
import PostContent, {
  GridColumns,
  Flex,
  H2SansUppercase,
  H2Serif,
  Section,
  SmallText,
  Text,
  Spacer,
} from "components/post/PostContent";
import { PostSummary } from "components/post/PostSummary";
import Sticky from "components/post/Sticky";
import ContentWithIcon from "components/post/ContentWithIcon";
import Image from "components/post/Image";
import FadeIn from "components/post/FadeIn";
import { spacing } from "utils/styles";

export default function TWBuildingMonitoring() {
  return (
    <>
      <FadeIn>
        <Sticky>
          {postMeta.map((item, index) => (
            <Flex key={index}>
              <div>{item.icon}</div>
              <div>{item.label}</div>
            </Flex>
          ))}
        </Sticky>
      </FadeIn>
      <PostSummary>
        <Text>
          In smart buildings, building managers often need to monitor many
          disparate systems and devices to understand what requires their
          attention, which can get overwhelming.
        </Text>
        <Text>
          The product team at Thoughtwire worked to improve this experience by
          using alerts and contextual information to help building managers
          identify building issues and respond proactively. I led design for
          this project.
        </Text>
      </PostSummary>

      <PostContent>
        <>
          <Section id="Discovery">
            <H2SansUppercase>DISCOVERY</H2SansUppercase>
            <H2Serif>
              Building managers want to respond to building issues before they
              happen
            </H2Serif>
            <Text>
              Keeping tenants (e.g. office employees) comfortable is essential
              to their productivity. As a result, building staff must be on top
              of building issues. However, while information about building
              issues exists, it often lives in separate systems. So we sought to
              bring this information together.
            </Text>
            <ContainerWithBorder title="We had 3 UX goals to help building managers">
              <GridColumns>
                {discoveryItems.map((item, index) => (
                  <ContentWithIcon
                    icon={item.icon}
                    title={item.title}
                    key={index}
                  >
                    <Text>{item.value}</Text>
                  </ContentWithIcon>
                ))}
              </GridColumns>
              <Image
                url="https://res.cloudinary.com/drsp4xifi/image/upload/v1665636547/Portfolio_v3/Experience_Map_zolqlh.png"
                alt="An experience map showing a building manager's journey from when an alert is triggered until it is resolved. Only phases that correspond to monitoring are highlighted."
                backgroundShadow={false}
              />
            </ContainerWithBorder>
          </Section>
          <Section id="Design">
            <H2SansUppercase>DESIGN</H2SansUppercase>
            <H2Serif>We needed to make the data we had useful</H2Serif>
            <Text>
              With so much data available, we had to be intentional with what we
              chose to display and how. Additionally, we expected to learn as we
              built, so we kept things simple. Wireframes and prototypes helped
              facilitate discussions.
            </Text>
            <SectionWithGrayBackground>
              <H2SansUppercase textAlign="center">
                Some features we built to improve the lives of building managers
              </H2SansUppercase>
              {features.map((item, featureIndex) => (
                <ContentWithMedia
                  key={featureIndex}
                  type={item.media.type}
                  url={item.media.url}
                  alt={item.media.alt}
                  backgroundShadow={false}
                  padding={spacing.xxsmall_2}
                >
                  <ContentWithIcon icon={item.icon} title={item.title}>
                    {item.text.map((text, textIndex) => (
                      <Text key={textIndex}>{text}</Text>
                    ))}
                  </ContentWithIcon>
                </ContentWithMedia>
              ))}
            </SectionWithGrayBackground>
          </Section>
          <Section id="Development">
            <H2SansUppercase>DEVELOPMENT</H2SansUppercase>

            <H2Serif>
              Component reuse was key in building fast and maintaining
              consistency
            </H2Serif>
            <Text>
              We used existing components to speed up the development process.
              We also identified new components along with all variants. In
              addition, documentation in the form of user-flows and text helped
              communication during the development phase.
            </Text>
            <Image
              url="https://res.cloudinary.com/drsp4xifi/image/upload/v1666223498/Portfolio_v3/Monitoring_userflows_qofagh.png"
              alt="Userflows and annotated mockups created in figma to facilitate communication with engineering in the development phase."
              backgroundShadow={true}
              marginTop={spacing.xlarge_48}
            />
          </Section>
          <Section id="Impact">
            <H2SansUppercase>IMPACT</H2SansUppercase>
            <H2Serif>
              Building staff could monitor building issues and respond
              proactively
            </H2Serif>
            <Text>
              With alerts we could inform building staff of both active and
              foreseen issues. For the project's subsequent phases, we will be
              adding the ability for building staff to communicate and trigger
              workflows. By tracking actions, we can measure and optimize for
              metrics like the time taken to respond to alerts.
            </Text>
            <Spacer />
          </Section>
        </>
      </PostContent>
    </>
  );
}

const discoveryItems = [
  {
    icon: "🏢",
    title: "Monitoring",
    value: (
      <>
        See what is happening and identify what needs attention.
        <SmallText>
          ✅ &nbsp;<em>We prioritized this for phase 1.</em>
        </SmallText>
      </>
    ),
  },
  {
    icon: "✉️",
    title: "Communication",
    value: "Communicate with tenants and staff about building issues.",
  },
  {
    icon: "🛠",
    title: "Automation",
    value: "Trigger workflows automatically based on defined rules.",
  },
];

const postMeta = [
  {
    icon: "👩🏾‍💻",
    label: "Designer",
  },
  {
    icon: "🏢",
    label: "Thoughtwire",
  },
  {
    icon: "🛠",
    label: "Figma",
  },
  {
    icon: "🤝",
    label: "PM, Data Analyst, Engineers",
  },
  {
    icon: "📆",
    label: "3 months (2022)",
  },
];

const features = [
  {
    icon: "🕵️‍♀️",
    title: "Monitoring alerts with list views",
    text: [
      <>
        Lists provided a relatively simple and scalable solution for monitoring
        alerts.
      </>,
      <>
        The alerts list view shows all active building issues. Building staff
        can determine what needs attention using data points such as the alert's
        priority and the number of people affected.
      </>,
    ],
    media: {
      type: "video",
      alt: `A building staff sees all active alerts in a list, including the priority and number of people impacted. Clicking on an alert item opens the details view, where they can see more contextual information such as related work orders.`,
      url: "https://res.cloudinary.com/drsp4xifi/video/upload/v1665776658/Portfolio_v3/Alerts_cxr01z.mov",
    },
  },
  {
    icon: "✂️",
    title: "Finding specific information with filters",
    text: [
      <>
        We added support for filtering to make it easy to find specific
        information, such as all alerts in the same zone.
      </>,
      <>
        Building staff use CSV tools often, so we kept the filter interaction
        similar. For example, filterable columns have a filter icon in the table
        header, and we highlight the table header when there is an active
        filter.
      </>,
    ],
    media: {
      type: "video",
      alt: `Building staff can filter alerts by certain criteria, such as zone and priority. When a filter is applied, the column's table header is highlighted. There is also a quick way to clear all applied filters in the table information panel.`,
      url: "https://res.cloudinary.com/drsp4xifi/video/upload/v1665776740/Portfolio_v3/Filters_m2i9rf.mov",
    },
  },
  {
    icon: "🗺",
    title: "Monitoring zones with floor map views",
    text: [
      <>
        A map view makes it easy to see what is happening in any building area.
        Users can switch between floors and overlay metrics on the map using
        filters.
      </>,
      <>
        The details view contextualizes what is happening in the zone with
        information such as current metrics and work orders.
      </>,
    ],
    media: {
      type: "video",
      alt: "Building staff can overlay up to 2 metrics on a map. Additional information in the details view, such as current metrics and work orders, can help with investigating building issues.",
      url: "https://res.cloudinary.com/drsp4xifi/video/upload/v1665778167/Portfolio_v3/Floor_map_2_kjthwk.mov",
    },
  },
  {
    icon: "🎨",
    title: "Distilling information with iconography & colour",
    text: [
      <>
        We used iconography and color to distill information and focus attention
        while keeping accessibility in mind.
      </>,
      <>
        In the alerts list and on floor maps, icons and colors communicate each
        alert's priority. We also use icons to show information on maps and help
        users quickly identify the metric.
      </>,
    ],
    media: {
      type: "image",
      alt: "We improved the map labels to use a combination of iconography, color, and text to distill information. We also use iconography in filter options as a legend.",
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1666222330/Portfolio_v3/Icons_yv2z3f.png",
    },
  },
];
