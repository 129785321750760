import React from "react";
import WorkCard from "components/home/WorkCard";
import { posts } from "utils/postsconfig";
import LogoUrl from "assets/images/ama-memoji.png";
import {
  breakPoints,
  fontColor,
  fontFamily,
  fontSize,
  fontWeight,
  scrollbarNone,
  spacing,
} from "utils/styles";
import { Link } from "react-router-dom";
import Profile from "components/home/Profile";
import styled from "styled-components";

const bio = {
  name: "Chiamaka",
  role: "Product Designer",
};

export const Home = () => {
  return (
    <HomeWrapper>
      <Container>
        <BioWrapper>
          <img src={LogoUrl} alt="" />
          <h1>{bio.name}</h1>
          <p>{bio.role}</p>
        </BioWrapper>
      </Container>
      <Container>
        <StyledH2>📚 WORK</StyledH2>
      </Container>
      <Container className={scrollbarNone}>
        <WorksWrapper>
          {posts?.map(
            (post, index) =>
              !post.hidden && (
                <Link to={`posts/${post.shortTitle}`} key={post.id}>
                  <WorkCard
                    company={post.company}
                    title={post.title}
                    subtitle={post.subtitle}
                    tags={post.tags}
                    image={post.image}
                    color={post.color}
                    verticalView={verticalView}
                  />
                </Link>
              )
          )}
        </WorksWrapper>
      </Container>
      <Container>
        <StyledH2>👋 ABOUT</StyledH2>
        <Container>
          <Profile />
        </Container>
      </Container>
    </HomeWrapper>
  );
};

const verticalView: Boolean = true;

const Container = styled.div`
  padding-left: ${spacing.medium_16};
  padding-right: ${spacing.medium_16};

  @media (min-width: ${breakPoints.mediumDevices}) {
    padding-left: ${spacing.xxlarge_80};
    padding-right: ${spacing.xxlarge_80};
  }
`;

const HomeWrapper = styled.div`
  max-width: ${breakPoints.largeDevices};
  padding-bottom: ${spacing.xxlarge_80};
  margin: auto;
`;

const BioWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;

  img {
    height: 60px;
  }

  h1 {
    font-family: ${fontFamily.DMSerif};
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.xxxlarge};
    color: ${fontColor.secondary};
    margin: ${spacing.medium_16} 0;
  }

  p {
    color: ${fontColor.quartenary};
  }
`;

const WorksWrapper = styled.div`
  display: flex;
  flex-direction: ${verticalView ? "column" : "row"};
  overflow-x: ${verticalView ? "unset" : "auto"};
  gap: ${spacing.large_24};
  padding-bottom: ${spacing.xxlarge_80};
`;

const StyledH2 = styled.h2`
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.regular};
  margin-bottom: ${spacing.medium_16};
  padding-left: ${spacing.large_24};
  padding-right: ${spacing.large_24};
  color: ${fontColor.quartenary};

  @media (min-width: ${breakPoints.smallDevices}) {
    padding-left: ${spacing.xxlarge_80};
    padding-right: ${spacing.xxlarge_80};
  }
`;
