import React from "react";
import styled from "styled-components";
import { breakPoints, fontSize, spacing } from "utils/styles";
import Image from "components/post/Image";
import Video from "components/post/Video";

export default function ContentWithMedia({
  children,
  url,
  alt,
  icon,
  type,
  backgroundShadow,
  imageHeight,
  padding
}: any) {
  return (
    <Wrapper>
      <ContentWrapper>
        <Icon>{icon}</Icon>
        {children}
      </ContentWrapper>
      <MediaWrapper>
        {type === "video" ? (
          <Video alt={alt} url={url} />
        ) : (
          <Image
            imageHeight={imageHeight}
            backgroundShadow={backgroundShadow}
            padding={padding}
            url={url}
            alt={alt}
          />
        )}
      </MediaWrapper>
    </Wrapper>
  );
}

/* STYLES */
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: ${spacing.medium_16};
  padding: ${spacing.xlarge_48} 0;
  max-width: ${breakPoints.largeDevices};
  margin: auto;

  @media (min-width: ${breakPoints.mediumDevices}) {
    flex-direction: row;
    gap: ${spacing.xxlarge_80};
  }
`;
const ContentWrapper = styled.div`
  flex: 0 0 30%;
`;
const MediaWrapper = styled.div`
  flex: 0 0 calc(70% - ${spacing.xxlarge_80});
  animation: 1s ease-out 0s 1 slideInFromBottom;
`;
const Icon = styled.span`
  font-size: ${fontSize.large};
`;
