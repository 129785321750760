import React, { useContext } from "react";
import styled from "styled-components";
import { PostContext } from "layouts/postLayout";
import {
  backgroundColor,
  breakPoints,
  spacing,
} from "utils/styles";

export default function SectionWithGrayBackground({ children }: any) {
  const { post } = useContext(PostContext);

  return (
    <ContainerWithGrayBackground color={post?.color.lighter}>
      {children}
    </ContainerWithGrayBackground>
  );
}

/* STYLES */
const ContainerWithGrayBackground = styled.section`
  background-color: ${backgroundColor.grayUltraLight};
  padding: ${spacing.large_24};
  margin: ${spacing.xlarge_48} 0 0;

  @media (min-width: ${breakPoints.smallDevices}) {
    padding: ${spacing.xxlarge_80};
  }
`;
