import { color } from "utils/colors";

export const fontColor = {
  primary: color.gray700,
  secondary: color.gray600,
  tertiary: color.gray500,
  quartenary: color.gray400,
  purple: color.purple300,
  red: color.red300,
  orange: color.orange300,
  green: color.green300,
  pink: color.pink300,
  blue: color.blue300,
};

export const borderColor = {
  transparent: color.grayTransparent,
};

const backgroundGradient = (_color) =>
  `linear - gradient(180deg, rgba(247, 242, 255, 0) 0 %, ${_color} 27.08 %, ${_color} 100 %)`;

export const backgroundColor = {
  white: color.white,
  whiteTransparent: color.whiteTransparent,
  grayUltraLight: color.gray100,
  grayLight: color.gray200,
  grayTransparent: color.grayTransparent,
  red300: color.red300,
  red200: color.red200,
  red100: color.red100,
  redGradient: backgroundGradient(color.red100),
  purple300: color.purple300,
  purple200: color.purple200,
  purple100: color.purple100,
  purpleGradient: backgroundGradient(color.purple100),
  orange300: color.orange300,
  orange200: color.orange200,
  orange100: color.orange100,
  orangeGradient: backgroundGradient(color.orange100),
  green300: color.green300,
  green200: color.green200,
  green100: color.green100,
  greenGradient: backgroundGradient(color.green100),
  pink300: color.pink300,
  pink200: color.pink200,
  pink100: color.pink100,
  pinkGradient: backgroundGradient(color.pink100),
  blue300: color.blue300,
  blue200: color.blue200,
  blue100: color.blue100,
  blueGradient: backgroundGradient(color.blue100),
};

export const borderRadius = {
  small: "4px",
  medium: "8px",
  large: "20px",
  round: "1000px",
};

export const gridColumns = {
  four: "gridColumns-4",
  eight: "gridColumns-8",
};

export const boxShadow = {
  small: "0px 1px 4px rgba(0, 0, 0, 0.16)",
  medium: "0px 2px 4px rgba(0, 0, 0, 0.11)",
  large:
  "1px 2px 2px rgba(0, 0, 0, 0.03), 2px 4px 4px rgba(0, 0, 0, 0.03), 4px 8px 8px rgba(0, 0, 0, 0.03), 8px 16px 16px rgba(0, 0, 0, 0.03)",
};

export const fontFamily = {
  DMSans: "DM Sans, sans-serif",
  DMSerif: "DM Serif Text, serif",
};

export const fontSize = {
  xxxlarge: "47px",
  xxlarge: "39px",
  xlarge: "33px",
  large: "23px",
  regular: "19px",
  small: "16px",
  tiny: "13px",
};

export const fontWeight = {
  bold: 700,
  semibold: 600,
  medium: 500,
  regular: 400,
};

export const scrollbarNone = "scrollbar-none";

export const spacing = {
  none: "0px",
  xxsmall_2: "2px",
  xsmall_4: "4px",
  small_12: "12px",
  medium_16: "16px",
  large_24: "24px",
  xlarge_48: "48px",
  xxlarge_80: "80px",
};

/* BREAKPOINTS */
export const breakPoints = {
  xSmallDevices: "576px",
  smallDevices: "768px",
  mediumDevices: "992px",
  largeDevices: "1200px",
  xLargeDevices: "1440px",
};

export const lineHeight = {
  small: "120%",
  regular: "150%",
  large: "180%",
};
