import React from "react";
import styled from "styled-components";
import {
  borderRadius,
  boxShadow,
  breakPoints,
  fontColor,
  fontSize,
  spacing,
} from "utils/styles";

export default function Video({ url, alt }: any) {
  return (
    <StyledFigure>
      <StyledVideo muted controls>
        <source src={url} type="video/mp4" />
      </StyledVideo>
      <StyledFigCaption>{alt}</StyledFigCaption>
    </StyledFigure>
  );
}

/* STYLES */
const StyledVideo = styled.video`
  border-radius: ${borderRadius.medium};
  width: 100%;
  margin: auto;
  max-width: ${breakPoints.mediumDevices};
  box-shadow: ${boxShadow.large};
  border-radius: ${borderRadius.medium};
  display: block;
`;

export const StyledFigCaption = styled.figcaption`
  font-size: ${fontSize.tiny};
  font-style: italic;
  text-align: center;
  color: ${fontColor.quartenary};
  line-height: normal;
  margin: ${spacing.medium_16} ;
`;
export const StyledFigure = styled.figure`
  text-align: center;
`;
