import React from "react";
import styled from "styled-components";
import { borderRadius, boxShadow, breakPoints } from "utils/styles";
import { StyledFigCaption } from "./Video";

export default function Image({
  url,
  alt,
  backgroundShadow,
  imageHeight,
  padding,
  marginTop,
}: any) {
  return (
    <StyledFigure marginTop={marginTop}>
      <StyledImageWrapper padding={padding} backgroundShadow={backgroundShadow}>
        <StyledImage height={imageHeight} src={url} alt={alt} />
      </StyledImageWrapper>
      <StyledFigCaption>
        {alt}
      </StyledFigCaption>
    </StyledFigure>
  );
}

/* STYLES */
export const StyledImageWrapper = styled.div<{
  backgroundShadow?: Boolean;
  padding: any;
}>`
  width: 100%;
  margin: auto;
  max-width: ${breakPoints.mediumDevices};
  box-shadow: ${(props) =>
    props.backgroundShadow ? boxShadow.small : "unset"};
  border-radius: ${borderRadius.large};
  overflow: hidden;
  text-align: center;
  padding: ${(props) => (props.padding ? props.padding : "unset")};
`;

export const StyledImage = styled.img<{
  backgroundShadow?: Boolean;
  height?: Number;
}>`
  width: 100%;
  height: auto;
  margin: auto;
  display: block;

  @media (min-width: ${breakPoints.smallDevices}) {
    grid-template-columns: repeat(2, 1fr);
    width: ${(props) => (props.height ? "unset" : "100%")};
    height: ${(props) => (props.height ? props.height : "unset")};
  }

`;

export const StyledFigure = styled.figure<{
  marginTop?: any;
}>`
  text-align: center;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
`;
