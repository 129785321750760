import React from "react";
import { ContainerWithBorder } from "components/post/ContainerWithBorder";
import ContentWithMedia from "components/post/ContentWithMedia";
import SectionWithGrayBackground from "components/post/ContainerWithGrayBackground";
import PostContent, { Spacer } from "components/post/PostContent";
import { PostSummary } from "components/post/PostSummary";
import Sticky from "components/post/Sticky";
import ContentWithIcon from "components/post/ContentWithIcon";
import Image from "components/post/Image";
import FadeIn from "components/post/FadeIn";
import {
  GridColumns,
  Flex,
  H2SansUppercase,
  H2Serif,
  Section,
  Text,
} from "components/post/PostContent";
import { spacing } from "utils/styles";

export default function TWWorkstationBooking() {
  return (
    <>
      <FadeIn>
        <Sticky>
          {postMeta.map((item, index) => (
            <Flex key={index}>
              <div>{item.icon}</div>
              <div>{item.label}</div>
            </Flex>
          ))}
        </Sticky>
      </FadeIn>
      <PostSummary>
        <Text>
          The product team at Thoughtwire wanted to add new features to our
          workstation booking solution. We used this opportunity to improve the
          booking experience by prioritizing accessibility and simplicity. I led
          the design process.
        </Text>
      </PostSummary>

      <PostContent>
        <>
          <Section id="Discovery">
            <H2SansUppercase>DISCOVERY</H2SansUppercase>
            <H2Serif>Maps alone were not an accessible</H2Serif>
            <Text>
              Maps helped users visualize the work area on a floor plan when
              booking a workstation. However, some users experienced
              difficulties navigating the map. Additionally, it was not
              navigable by screen readers. We aimed to fix these issues, along
              with other problem areas we identified.
            </Text>
            <ContainerWithBorder title="3 problem areas we identified">
              <GridColumns columns={3}>
                {discoveryItems.map((item, index) => (
                  <ContentWithIcon
                    icon={item.icon}
                    title={item.title}
                    key={index}
                  >
                    <Text>{item.value}</Text>
                  </ContentWithIcon>
                ))}
              </GridColumns>
              <Image
                url="https://res.cloudinary.com/drsp4xifi/image/upload/v1666479695/Portfolio_v3/Old_booking_rlqhnz.png"
                alt="The previous workstation booking experience with a map view."
                marginTop={spacing.large_24}
                backgroundShadow={false}
              />
            </ContainerWithBorder>
          </Section>
          <Section id="Design">
            <H2SansUppercase>DESIGN</H2SansUppercase>
            <H2Serif>
              The new booking experience prioritized accessibility and
              simplicity
            </H2Serif>
            <Text>
              For each purpose identified, I defined the behavior on both iOS
              and android platforms. <em>Apple's human interface guidelines</em>{" "}
              and <em>Google's material design</em> were essential resources in
              this process.
            </Text>
            <SectionWithGrayBackground>
              <H2SansUppercase textAlign="center">
                Key features and design decisions
              </H2SansUppercase>
              {features.map((item, featureIndex) => (
                <ContentWithMedia
                  key={featureIndex}
                  type={item.media.type}
                  url={item.media.url}
                  alt={item.media.alt}
                  backgroundShadow={false}
                >
                  <ContentWithIcon icon={item.icon} title={item.title}>
                    {item.text.map((text, textIndex) => (
                      <Text key={textIndex}>{text}</Text>
                    ))}
                  </ContentWithIcon>
                </ContentWithMedia>
              ))}
            </SectionWithGrayBackground>
          </Section>
          <Section id="Development">
            <H2SansUppercase>DEVELOPMENT</H2SansUppercase>
            <H2Serif>
              Native components provided an intuitive user experience
            </H2Serif>
            <Text>
              We leveraged native components, such as dialogs and action sheets,
              to confirm actions. With native components, we could build faster
              while providing a familiar user experience. User flows and
              prototypes played a crucial role in communicating the expected
              behavior of features.
            </Text>
            <Image
              url="https://res.cloudinary.com/drsp4xifi/image/upload/v1666570283/Portfolio_v3/Screen_Shot_2022-10-23_at_8.05.51_PM_ekjnb3.png"
              alt="A userflow in figma, demonstrating replacing a workstation booking."
              marginTop={spacing.xlarge_48}
              backgroundShadow={true}
            />
          </Section>
          <Section id="Impact">
            <H2SansUppercase>IMPACT</H2SansUppercase>
            <H2Serif>
              Booking workstations was made easier and more accessible
            </H2Serif>
            <Text>
              Ultimately we improved the experience of workstation booking,
              which is great! With the list view now accessible to screen
              readers, more users can book workstations. Additionally, the
              recommendations feature of the list view opens up opportunities to
              explore more innovative ways of helping users find workstations.
            </Text>
            <Spacer/>

          </Section>
        </>
      </PostContent>
    </>
  );
}

const discoveryItems = [
  {
    icon: "🔦",
    title: "Finding recent bookings",
    value:
      "Users wanted to book workstations they had previously booked again.",
  },
  {
    icon: "🗺",
    title: "Navigating the map",
    value:
      "Selecting locations on the map was difficult for some users, especially on the small mobile screen.",
  },
  {
    icon: "✏️",
    title: "Editing a booking",
    value:
      "To make changes to an existing booking, users had to delete it and create a new one.",
  },
];

const postMeta = [
  {
    icon: "👩🏾‍💻",
    label: "Designer",
  },
  {
    icon: "🏢",
    label: "Thoughtwire",
  },
  {
    icon: "🛠",
    label: "Figma",
  },
  {
    icon: "🤝",
    label: "Engineer & Designer",
  },
  {
    icon: "📆",
    label: "2 months (2022)",
  },
];

const features = [
  {
    icon: "",
    title: "Browse workstations in a list view",
    text: [
      <>
        We added a list view as an alternate way to book a workstation, making
        workstation booking accessible to screen readers.
      </>,
      <>
        Also, we moved the date and time selectors to the top of the screen.
        These UI changes made scrolling easier in the list view and switching
        between the list and map view smoother.{" "}
      </>,
    ],
    media: {
      type: "image",
      alt: `The improved workstation booking features a list view and a map view. Tapping on the map/list icon in the top right navigation switches between both views.`,
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1666479694/Portfolio_v3/List_View_jqlpam.png",
    },
  },
  {
    icon: "",
    title: "Find workstations with favorites and recommendations",
    text: [
      <>
        The list view allowed us to do more to improve the workstation browsing
        experience. For example, we added the ability to mark workstations as
        favorites and easily find them when creating a booking.
      </>,
      <>
        Also, we worked with the data team to provide workstation
        recommendations so that the best workstations show up first.{" "}
      </>,
    ],
    media: {
      type: "image",
      alt: "The list view for booking workstations has two main sections - favorites and recommendations. Users can mark a workstation as a favorite when booking or viewing booking details. .",
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1666479695/Portfolio_v3/Favorites_phtej4.png",
    },
  },
  {
    icon: "",
    title: "Replace a booking",
    text: [
      <>
        To provide the ability to make changes to bookings, we focused on
        minimizing errors. Therefore, we only showed available workstations for
        the selected time.
      </>,
      <>
        The first option we considered was a form where users could edit the
        booking's date and time. However, this approach we saw would increase
        the frequency of errors and could lead to user frustration, so we went
        with the alternative (option 2).
      </>,
    ],
    media: {
      type: "image",
      alt: `To make changes to a booking, we considered two options. Option 1 is a form that allows users to edit all booking fields. In contrast, Option 2 only shows workstations available for the selected time.`,
      url: "https://res.cloudinary.com/drsp4xifi/image/upload/v1666570283/Portfolio_v3/Replace_booking_2_dziqev.png",
    },
  },
];
